import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_v = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - V'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>V</h2>
     <LatQuotesIntro />
     <p><b>Vade in pace</b> - Go in peace</p>
<p><b>Vade mecum</b> - Come with me. A constant companion</p>
<p><b>Vae victis!</b> - Woe to the conquered! (vanquished) (Livy)</p>
<p><b>Vale</b> - Farewell</p>
<p><b>Vanitas vanitatum, omnis vanitas</b> - Vanity of vanities, all is vanity</p>
<p><b>Varia lecto (v.l.)</b> - Variant reading</p>
<p><b>Variatio delectat</b> - There's nothing like change! (Cicero)</p>
<p><b>Variorum</b> - Of various people</p>
<p><b>Velle est posse</b> - To be willing is to be able</p>
<p><b>Veni, vidi, vici</b> - I came, I saw, I conquered. (Julius Caesar)</p>
<p><b>Veni, Vidi, volo in domum redire</b> - I came, I saw, I want to go home</p>
<p><b>Venienti occurrite morbo</b> - Meet the misfortune as it comes. (Persius)</p>
<p><b>Venire facias</b> - You must come</p>
<p><b>Ventis secundis, tene cursum</b> - Go with the flow</p>
<p><b>Ventis secundis, tene/tenete cursum</b> - The winds being favorable, hold the course</p>
<p><b>Verba de futuro</b> - Words about the future</p>
<p><b>Verba movent, exempla trahunt</b> - Words move people, examples draw/compel them. Deeds, not words, give the example</p>
<p><b>Verba volant, (littera) scripta manet</b> - Words fly away, the written (letter) remains</p>
<p><b>Verbatim et litteratim</b> - Word for word and letter for letter</p>
<p><b>Verbatim</b> - Exactly as said</p>
<p><b>Verbum sapienti satis est (verb. sap.)</b> - A word to the wise is sufficient. Enough said</p>
<p><b>Veritas Lux Mea</b> - The truth enlightens me / The truth is my light</p>
<p><b>Veritas numquam perit</b> - Truth never perishes. (Seneca)</p>
<p><b>Veritas odit moras</b> - Truth hates delay. (Seneca)</p>
<p><b>Veritas vincit</b> - Truth conquers</p>
<p><b>Veritas vos liberabit</b> - The truth will set you free</p>
<p><b>Verso</b> - Reverse</p>
<p><b>Versus</b> - Against</p>
<p><b>Verum et factum convertuntur</b> - The true and the made are interchangeable. One can know with certainty only what he have created himself</p>
<p><b>Vesanum poetam qui sapiunt fugiunt</b> - Anyone with a brain flees a versifying poet</p>
<p><b>Vestigia terrent</b> - The footprints frighten me. (Horace)</p>
<p><b>Vestis virum reddit</b> - The clothes make the man. (Quintilianus)</p>
<p><b>Veto</b> - I forbid</p>
<p><b>Vi et armis</b> - By force and arms</p>
<p><b>Via Crucis</b> - The Way of the Cross</p>
<p><b>Via Dolorosa</b> - The Way of Sorrow</p>
<p><b>Via Lactea</b> - The Milky Way</p>
<p><b>Via media</b> - A middle way or course</p>
<p><b>Via</b> - By way of</p>
<p><b>Vice versa</b> - In reverse order</p>
<p><b>Vice</b> - In place of</p>
<p><b>Victis honor</b> - Honour to the vanquished</p>
<p><b>Victoria Imperatrix Regina (VIR)</b> - Victoria, Empress and Queen</p>
<p><b>Victoria Regina (VR)</b> - Queen Victoria</p>
<p><b>Victoria Regina et Imperatrix (VRI)</b> - Victoria, Queen and Empress</p>
<p><b>Victoria, non praeda</b> - Victory, not loot</p>
<p><b>Victurus te saluto</b> - He who is about to win salutes you</p>
<p><b>Vide et credere</b> - See and believe</p>
<p><b>Vide ut supra</b> - See the above</p>
<p><b>Vide</b> - See</p>
<p><b>Videlicet (viz.)</b> - That is to say; To wit; Namely</p>
<p><b>Video meliora proboque deteriora sequor</b> - I see the better way and approve it, but I follow the worse way</p>
<p><b>Videre est credere</b> - Seeing is believing</p>
<p><b>Videtis quantum scelus contra rem publicam vobis nuntiatum sit?</b> - How great an evil do you see that may have been announced by you against the Republic? (Cicero)</p>
<p><b>Vidistine nuper imagines moventes bonas?</b> - Seen any good movies lately?</p>
<p><b>Vigilando, agendo, bene consulendo, prospera omnia cedunt</b> - By watching, by doing, by counsulting well, these things yield all things prosperous. (Sallust)</p>
<p><b>Vincere est totum</b> - To win is everything</p>
<p><b>Vincit omnia amor</b> - Love conquers all</p>
<p><b>Vincit omnia veritas</b> - Truth conquers all</p>
<p><b>Vincit qui se vincit</b> - He conquers who conquers himself</p>
<p><b>Vinculum unitatis</b> - The bond of unity</p>
<p><b>Vinum bellum iucunumque est, sed animo corporeque caret</b> - It's a nice little wine, but it lacks character and depth</p>
<p><b>Vinum et musica laetificant cor</b> - Wine and music gladden the heart</p>
<p><b>Vir bonus, dicendi peritus</b> - A good man, skilled in speaking. (definition of an orator) (Cato the Elder)</p>
<p><b>Vir prudens non contra ventum mingit</b> - A wise man does not urinate against the wind</p>
<p><b>Vir sapit qui pauca loquitor</b> - It is a wise man who speaks little</p>
<p><b>Vir sapit qui pauca loquitur</b> - That man is wise who talks little (know when to hold your tongue)</p>
<p><b>Vires acquirit eundo</b> - It gains strength by going / as it goes. (Virgil)</p>
<p><b>Virginibus puerisque</b> - For maidens and youths</p>
<p><b>Virgo intacta</b> - Intact virgin</p>
<p><b>Virtus in medio stat</b> - Virtue stands in the middle</p>
<p><b>Virtus probata florescit</b> - Manly excellence in trial flourished</p>
<p><b>Virtute et armis</b> - By courage and by arms</p>
<p><b>Virtutis fortuna comes</b> - Good luck is the companion of courage</p>
<p><b>Virum mihi, Camena, insece versutum</b> - Tell me, O Muse, of the skillful man. (Livius Andronicus)</p>
<p><b>Vis consili expers mole ruit sua</b> - Brute force bereft of wisdom falls to ruin by its own weight. (Discretion is the better part of valor) (Horace)</p>
<p><b>Vis inertiae</b> - The power of inertia</p>
<p><b>Vis maior</b> - Higher force</p>
<p><b>Vis medicatrix naturae</b> - The healing power of nature</p>
<p><b>Visa</b> - Things seen</p>
<p><b>Vita brevis, ars lunga</b> - Life is short, art is long</p>
<p><b>Vita mutatur, non tollitur</b> - Life is changed, not taken away</p>
<p><b>Vita non est vivere sed valere vita est</b> - Life is more than merely staying alive</p>
<p><b>Vita sine libris mors est</b> - Life without books is death</p>
<p><b>Vitam impendere vero</b> - To risk one's life for the truth</p>
<p><b>Vitam regit fortuna, non sapientia</b> - Fortune, not wisdom, rules lives. (Cicero)</p>
<p><b>Vitanda est improba siren desidia</b> - One must avoid that wicked temptress, Laziness. (Horace)</p>
<p><b>Vitiis nemo sine nascitur</b> - No one is born without faults. (Horace)</p>
<p><b>Viva voce</b> - With living voice</p>
<p><b>Vivat regina</b> - Long live the queen</p>
<p><b>Vivat rex</b> - Long live the king</p>
<p><b>Vivat, crescat, floreat!</b> - May he/she/it live, grow, and flourish!</p>
<p><b>Vive hodie</b> - Live today (not tomorrow)</p>
<p><b>Vive ut vivas</b> - Live that you may live</p>
<p><b>Vivere commune est, sed non commune mereri</b> - Everybody lives; not everybody deserves to</p>
<p><b>Vivere disce, cogita mori</b> - Learn to live; Remember death. (sundial inscription)</p>
<p><b>Vivos voco, mortuos plango</b> - I call the living, I mourn the dead. (church bell inscription)</p>
<p><b>Vix ulla tam iniqua pax, quin bello vel aequissimo sit potior</b> - Scarcely is there any peace so unjust that it is better than even the fairest war. (Erasmus)</p>
<p><b>Vixere fortes ante agamemnona</b> - Brave men lived before Agamemnon. (heroism exists even if it's not recorded)</p>
<p><b>Vixit</b> - He/she has lived</p>
<p><b>Volens et potens</b> - Willing and able</p>
<p><b>Volente Deo</b> - God willing</p>
<p><b>Volenti non fit iniuria</b> - A person who consents does not suffer injustice</p>
<p><b>Volo, non valeo</b> - I am willing but unable</p>
<p><b>Voluptates commendat rarior usus</b> - Infrequent use commends pleasure. (moderation in all things)</p>
<p><b>Vos vestros servate, meos mihi linquite mores</b> - You cling to your own ways and leave mine to me. (Petrarch)</p>
<p><b>Vox clamantis in deserto</b> - Voice crying in the desert. (voice in the wilderness unheeded warning, an opinion not in the mainstream</p>
<p><b>Vox populi</b> - The voice of the people</p>
<p><b>Vox populi, vox Dei</b> - The voice of the people is the voice of God. (Public opinion is obligatory)</p>
<p><b>Vulnerant omnes, ultima necat</b> - Every (hour) wounds, the last kills. (sundial inscription)</p>
<p><b>Vulpem pilum mutat, non mores</b> - A fox may change its hair, not its tricks. (People change behaviour but not their aims)</p>
<p><b>Vultus est index animi</b> - The face is the index of the soul/mind</p>

   </Layout>
  )
}

export default LatQuotes_v
